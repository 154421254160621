@import "./themes/variables";

$navbar-padding-y: 0.5rem;

@import "~bootstrap/scss/bootstrap";


.game-card {
    background-size: cover;
    @extend .rounded;
    height: 7rem;
    cursor: pointer;

    transition: all 100ms ease-out;
    &:hover {
        transform: scale(1.05);
    }

    display: flex;

    & .game-card-content {
        background-color: rgba(0, 0, 0, 0.5);
        flex-grow: 1;
        @extend .rounded;
        @extend .p-2;
    }
}

a.text-reset {
    &:hover, &:focus {
        text-decoration: none;
    }
}

.td-empty {
    @extend .p-5;
    text-align: center;
}